import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import firstbornImage from '../../assets/images/deities/firstbornsymbol.jpg';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const FirstbornSoulbound = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="deity-path-page">
      <div className="deity-path-hero">
        <img 
          src={firstbornImage} 
          alt="Firstborn: Soulbound Path" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>Firstborn: The Soulbound Path</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Veiled Paths
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Ascension
                </Link>
                <Link to="/deities/caelumbris/shadowed" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Caelumbris: Shadowed
                </Link>
                <Link to="/deities/libris/knowledge" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.: Knowledge
                </Link>
                <Link to="/deities/nebula/mistwalker" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Nebula: Mistwalker
                </Link>
                <Link to="/deities/queenmab/frozenveil" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Mab: Frozen Veil
                </Link>
                <Link to="/deities/queen-titania/wildgrowth" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania: Wildgrowth
                </Link>
                <Link to="/deities/sacrathor/dominion" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor: Dominion
                </Link>
                <Link to="/deities/valdis/justice" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Valdís: Justice
                </Link>
                <Link to="/deities/wildscar/emberfury" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Wildscar: Ember Fury
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="deity-details">
        <div className="content-block requirements-section">
          <div className="edicts">
            <h2>Edicts</h2>
            <ul>
              <li>Uphold All Oaths: Honor every oath, contract, and verbal agreement without exception.</li>
              <li>Respect the Natural Order: Intervene only when the balance between life and death is disrupted; destroy mindless undead.</li>
              <li>Foster Pacts: Create or arbitrate pacts that promote mutual growth and maintain equilibrium.</li>
            </ul>
          </div>
          
          <div className="requirements">
            <h2>Requirements</h2>
            <ul>
              <li>Absolute Commitment: Every agreement entered must be honored, even at great personal cost.</li>
              <li>Active Mediation: Seek opportunities to mediate disputes and ensure fairness in all pacts.</li>
              <li>Undead Restriction: Never raise undead creatures except to serve as guardians of sacred sites or to fulfill the terms of a pact.</li>
            </ul>
          </div>
        </div>

        <div className="content-block dark-block">
          <h2 className="blue-text">Firstborn: Soulbound Covenant</h2>
          <p>
            In the depths of mortality's end and the silence beyond, the Firstborn reigns as the impartial arbiter of oaths and balance. As the God of Death and Pacts, the Firstborn ensures that every promise, every solemn vow, and every shared bond is honored without fail. For those who follow this path, no act is more sacred than forging a pact—an immutable covenant that binds souls with divine precision. Every agreement, regardless of its purpose, carries the weight of inevitability; breaking a vow invites dire and unyielding consequences.
          </p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>The Firstborn's Domain</h3>
          </div>
          <p>
            The Firstborn's realm lies at the nexus of life and death—a stark expanse where the weight of every promise ever made presses upon the soul. Towering monoliths, etched with the pacts of countless beings, stretch endlessly into the void, their inscriptions glowing faintly with divine energy. The air thrums with the resonance of myriad vows, whispered eternally in the silence of this solemn place.
          </p>
          <p>
            To traverse this domain is to feel the inescapable gravity of accountability. Every shadow echoes with the memory of broken oaths, and every step tests the resolve of those who dare walk this path. Here, the Firstborn's gaze pierces all deceptions, ensuring that every pact is upheld with unwavering finality.
          </p>
        </div>

        <div className="content-block">
          <h2>Levels and Abilities</h2>
          <div className="trait">
            <div className="trait-header">
              <h3>Level 3: Oathbound Initiate</h3>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>Soulbound Pact</h3>
              </div>
              <p>
                You can forge a Soulbound Pact with a number of creatures equal to your proficiency bonus. Each pact lasts for 8 hours and requires mutual consent from all participants. The Firstborn enforces each agreement impartially, and breaking a pact incurs immediate and severe consequences for violators.
              </p>          

              <h4>Breaking the Pact</h4>
              <ul className="specializations-list">
                <li><strong>Penalties for All Violators:</strong> Breaking the terms of a pact results in a temporary reduction to maximum hit points equal to the violator's level plus 2× your proficiency bonus. This penalty lasts for a number of days equal to the violator's level.</li>
                <li><strong>Additional Penalties for Initiators:</strong> If the initiator breaks the pact, they lose the ability to forge new pacts and temporarily lose one character level—and all associated benefits—for a number of days equal to their level.</li>
              </ul>

              <h4>Pact Benefits</h4>
              <p>Each Soulbound Pact confers a divine bonus equal to your proficiency bonus, applied to the chosen benefit. When you forge a pact, choose one of the following types—each with its own benefit and requirement:</p>
              
              <h4>Pact of Combat</h4>
              <ul className="specializations-list">
                <li><strong>Benefit:</strong> Each participant gains a divine bonus to attack and damage rolls.</li>
                <li><strong>Requirement:</strong> All members must actively contribute to combat, whether through direct attacks, providing buffs, or other meaningful actions in the midst of battle.</li>
              </ul>

              <h4>Pact of Defense</h4>
              <ul className="specializations-list">
                <li><strong>Benefit:</strong> Each participant gains a divine bonus to AC and resistance to piercing, slashing, and bludgeoning damage.</li>
                <li><strong>Requirement:</strong> Participants must prioritize defending one another—through casting protective spells, employing abilities to shield allies, or forming defensive positions on the battlefield.</li>
              </ul>

              <h4>Pact of Resolve</h4>
              <ul className="specializations-list">
                <li><strong>Benefit:</strong> Each participant gains a divine bonus to one saving throw of their choice (select from Strength, Dexterity, Constitution, Intelligence, Wisdom, or Charisma).</li>
                <li><strong>Requirement:</strong> Participants must actively work together to overcome hazards—sharing resources, combining abilities, or supporting each other through challenging encounters.</li>
              </ul>

              <h4>Pact of Mastery</h4>
              <ul className="specializations-list">
                <li><strong>Benefit:</strong> Each participant gains a divine bonus to skill checks.</li>
                <li><strong>Requirement:</strong> Participants must collaborate on skill challenges, ensuring that no individual attempts critical tasks alone unless absolutely necessary.</li>
              </ul>

              <h4>Pact of Community</h4>
              <ul className="specializations-list">
                <li><strong>Benefit:</strong> Each participant gains temporary hit points equal to twice your proficiency bonus plus their level, along with advantage on death saving throws.</li>
                <li><strong>Requirement:</strong> Participants must foster teamwork, honesty, and mutual support, avoiding selfish actions that could undermine group cohesion.</li>
              </ul>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Pactforged Endurance</h3>
              </div>
              <p>While part of a Soulbound Pact, you gain temporary hit points equal to your proficiency bonus at the start of each of your turns. Additionally, if a Soulbound Pact is broken, all remaining members gain advantage on saving throws against the violator's abilities until the violator's penalty expires.</p>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Level 5: Covenant Warden</h3>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>Guardian Spirits</h3>
              </div>
              <p>A number of ethereal guardians equal to your proficiency bonus surround you, shielding you from harm. Each guardian grants a +1 bonus to AC. When you are hit, one guardian dissipates, negating half the damage you would take. These spirits renew each dawn.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Chains of Fate</h3>
              </div>
              <p>When you hit a creature with an attack or spell, ethereal chains shimmer around it, binding the target with the memory of every broken promise. For a number of rounds equal to your proficiency bonus, the target suffers disadvantage on all d20 rolls. At the end of each turn, the target may make a Wisdom saving throw to break free from this mental torment.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Chains of Dominion</h3>
              </div>
              <ul className="specializations-list">
                <li>If a creature fails two consecutive Wisdom saving throws against Chains of Fate, it becomes incapacitated until the end of its next turn.</li>
                <li>Any creature under Chains of Fate that attempts to teleport, plane shift, or escape magically must succeed on a Wisdom saving throw or be stunned until the end of its next turn.</li>
              </ul>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Level 10: Oathbound Harbinger</h3>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>Spirit Storm</h3>
              </div>
              <p>The souls bound to the Firstborn fill the area, wailing in lament. A 15-foot radius around you becomes engulfed in howling spirits. Creatures of your choice within the area take 6d6 psychic and necrotic damage at the start of their turn. You can use this ability a number of times equal to your proficiency bonus per long rest.</p>
            </div>

            <div className="trait">
              <div className="trait-header">
                <h3>Phantom Tribunal</h3>
              </div>
              <p>Within the radius of Spirit Storm, the voices of those bound by the Firstborn judge every participant. Allies who currently have an active pact with you gain advantage on saving throws, while those who have broken a vow suffer disadvantage on saving throws.</p>
            </div>
          </div>

          <div className="trait">
            <div className="trait-header">
              <h3>Level 15: Champion of Fate</h3>
            </div>
            <div className="trait">
              <div className="trait-header">
                <h3>Eternal Form</h3>
              </div>
              <p>As a bonus action, you may shift between three forms.</p>

              <h4>Mortal Form (Normal)</h4>
              <p>You remain in your natural state, bound by your pacts and mortality, and gain the following benefits:</p>
              <ul className="specializations-list">
                <li><strong>Reactive Mitigation:</strong> When you take damage, you can use your reaction to reduce that damage by an amount equal to your proficiency bonus.</li>
                <li><strong>Supportive Presence:</strong> Allies within 10 feet of you gain a bonus to saving throws equal to half your proficiency bonus (rounded down).</li>
                <li><strong>Emergency Vitality:</strong> Once per long rest, when your hit points drop below 50% of your maximum, you may use a bonus action to regain hit points equal to twice your proficiency bonus.</li>
                <li><strong>Resolute Counter:</strong> You may reroll one saving throw per long rest after seeing the result but before its outcome is determined.</li>
              </ul>

              <h4>Spirit Form</h4>
              <p>In Spirit Form, you become incorporeal and gain a flying speed of 60 feet. You also gain resistance to all non-magical damage, necrotic damage, and cold damage, immunity to disease, and the ability to move through solid objects and creatures. (If you end your turn inside an object, you are shunted to the nearest unoccupied space and take 1d10 force damage for every 5 feet moved.)</p>
              <ul className="specializations-list">
                <li><strong>Enhanced Offense:</strong> When you hit a creature with an attack, you deal an extra 1d8 psychic damage. The target must then succeed on a Wisdom saving throw (DC = your ability modifier + proficiency bonus) or lose its reaction until the start of its next turn.</li>
                <li><strong>Evasive Reaction:</strong> Once per turn, when an enemy makes an attack roll against you, you can use your reaction to impose disadvantage on that attack roll.</li>
                <li><strong>Intangible Shift:</strong> You ignore extra movement costs from difficult terrain, and opportunity attacks against you are made with disadvantage.</li>
              </ul>

              <h4>Elite Skeletal Form</h4>
              <p>In Elite Skeletal Form, your AC becomes 18 + your Dexterity modifier, and you gain resistance to piercing and cold damage as well as immunity to necrotic damage and disease.</p>
              <ul className="specializations-list">
                <li><strong>Augmented Attacks:</strong> Your melee attacks deal an extra 1d6 necrotic damage and count as magical for the purpose of overcoming resistances.</li>
                <li><strong>Frightful Aura:</strong> Any hostile creature that starts its turn within 10 feet of you must succeed on a Wisdom saving throw (DC = your ability modifier + proficiency bonus) or become frightened until the start of its next turn.</li>
                <li><strong>Life Reclamation:</strong> When you reduce a creature to 0 hit points with a melee attack, you regain hit points equal to your proficiency bonus.</li>
                <li><strong>Bone-Shattering Roar (Recharge 5–6):</strong> As an action, you unleash a roar. Every hostile creature within 15 feet must succeed on a Constitution saving throw (DC = your ability modifier + proficiency bonus) or become frightened until the end of its next turn and take 2d8 necrotic damage.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={firstbornImage} 
              alt="Firstborn: Soulbound Path"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={firstbornImage}
              download="Firstborn.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default FirstbornSoulbound;